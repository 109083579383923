import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'
import { Index } from 'app/Index'

ReactDOM.render(<React.StrictMode>
  <Index/>
</React.StrictMode>, document.getElementById('root'))

serviceWorker.unregister()

import React, { Fragment } from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { ExpandMore } from '@material-ui/icons'
import PropTypes from 'prop-types'

import { Section } from 'app/project/Section'
import { useStyles } from 'app/project/Project.css'

const Header = ({ value }) => <Fragment>
  <Grid container alignItems={'flex-end'}>
    <Grid item xs={12} sm={12} md={3} lg={3}>
      <Typography variant={'h6'}><b>{value.title}</b></Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={9} lg={9} className={useStyles().subtitle}>
      <Typography variant={'caption'} color={'textSecondary'}> <b>{value.subtitle}</b> </Typography>
    </Grid>
  </Grid>
</Fragment>

Header.propTypes = {
  value: PropTypes.object.isRequired
}

export const Project = ({ value }) => <ExpansionPanel>
  <ExpansionPanelSummary expandIcon={<ExpandMore/>}>
    <Header value={value}/>
  </ExpansionPanelSummary>
  <ExpansionPanelDetails>
    <Grid container spacing={4}>
      {value.sections.map((section, index) => <Section key={index} value={section}/>)}
    </Grid>
  </ExpansionPanelDetails>
</ExpansionPanel>

Project.propTypes = {
  value: PropTypes.object.isRequired
}

import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'

import { Link } from 'app/project/Link'
import { useStyles } from 'app/project/Section.css'

export const Section = ({ value }) => <Grid item xs={12} sm={12} md={6} lg={6} className={useStyles().root}>
  <Box w={1} pb={2}>
    <Typography> {value.title} </Typography>
  </Box>

  {value.links.map((link, index) => <Link key={index} value={link}/>)}

  <Box w={1} pb={2}/>
</Grid>

Section.propTypes = {
  value: PropTypes.object.isRequired
}

import React from 'react'
import Chip from '@material-ui/core/Chip'
import PropTypes from 'prop-types'

const background = text => {
  if (text === 'dev') {
    return '#00BFA5'
  }

  if (text === 'stg') {
    return '#F4511E'
  }
}

const color = text => {
  if (text === 'dev' || text === 'stg') {
    return 'white'
  }
}

const style = text => ({
  background: background(text),
  color: color(text),
  fontWeight: 900,
  margin: 5
})

export const Tag = ({ text }) => <Chip label={text.toUpperCase()} style={style(text)} size={'small'}/>

Tag.propTypes = {
  text: PropTypes.string.isRequired
}

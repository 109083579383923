import React, { Fragment } from 'react'
import Box from '@material-ui/core/Box'

import { Title } from 'app/Title'
import { Content } from 'app/Content'

export const Index = () => <Fragment>
  <Title/>
  <Box py={2}/>
  <Content/>
</Fragment>

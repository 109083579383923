import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import logo from 'app/logo.png'

const Logo = () => <Typography align={'center'}>
  <img src={logo} width={60} height={60} alt={''}/>
</Typography>

const Text = () => <Fragment>
  <Typography variant={'h4'} align={'center'}>
    Nerdscomputing master
  </Typography>
  <Typography variant={'subtitle1'} align={'center'} color={'textSecondary'}>
    All the necessary links under our domain you might need during the development process...
  </Typography>
</Fragment>

export const Title = () => <Grid container>
  <Grid item xs={12} sm={12} md={12} lg={12}>
    <Box py={2}>
      <Logo/>
      <Text/>
    </Box>
  </Grid>
</Grid>

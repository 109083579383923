import React from 'react'
import Grid from '@material-ui/core/Grid'
import MuiLink from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'

import { Tag } from 'app/project/Tag'

export const Link = ({ value }) => <Grid container spacing={2}>
  <Grid item>
    {value.tags.map((tag, index) => <Tag key={index} text={tag}/>)}
  </Grid>

  <Grid item>
    <Box my={1}>
      <MuiLink href={value.uri}>{value.title}</MuiLink>
    </Box>
  </Grid>
</Grid>

Link.propTypes = {
  value: PropTypes.object.isRequired
}

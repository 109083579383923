import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'
import { tap } from 'rxjs/operators'

import { Project } from 'app/project/Project'
import { ProjectRepository } from 'app/project/repositories/project-repository'

const useProjects = () => {
  const [projects, setProjects] = useState([])

  useEffect(() => {
    ProjectRepository.all().pipe(tap(result => setProjects(result))).subscribe()
  }, [])

  return projects
}

export const Content = () => <Grid container justify={'center'}>
  <Grid item xs={12} sm={10} md={10} lg={8}>
    {useProjects().map((project, index) => <Project key={index} value={project}/>)}
  </Grid>
</Grid>
